<template>
  <AppShell id="app">
    <LayoutMolecule
      :loading="loading"
      :hasConfirmPaswordReset="passwordForm.passwordSent"
      @confirmPasswordReset="onConfirmPasswordReset"
      :isFirstStep="isFirstStep"
    >
      <SystemMessageOrganism app="login" />
      <LoginFormView v-if="isFirstStep && displayGitLogin" />
      <SelectionView v-if="isFirstStep" />
      <FrejaLoginView v-if="frejaLogin && !accessToken" />
      <FirstLoginView v-if="firstLogin" />
      <ClubFormView v-if="!firstLogin && clubForm.clubs.length > 0" />
      <PasswordFormView v-if="passwordForm.isActive" />
    </LayoutMolecule>
  </AppShell>
</template>
<script>
import types from './store/types';
import { mapState, mapActions } from 'vuex';
import { AppShell, ButtonAtom } from '@sgf/go-ace-ui';
import { LoginFormView, ClubFormView, PasswordFormView, FirstLoginView, FrejaLoginView, SelectionView } from './components/views';
import LayoutMolecule from './components/molecules/LayoutMolecule';
import SystemMessageOrganism from './components/organisms/SystemMessageOrganism';
const gitLogin = process.env.VUE_APP_GIT_LOGIN;
export default {
  name: 'app',
  components: {
    ButtonAtom,
    LoginFormView,
    ClubFormView,
    PasswordFormView,
    FirstLoginView,
    AppShell,
    SystemMessageOrganism,
    LayoutMolecule,
    FrejaLoginView,
    SelectionView,
  },
  computed: {
    ...mapState(['loading', 'error', 'clubForm', 'firstLogin', 'accessToken', 'passwordForm', 'frejaLogin']),
    isFirstStep() {
      return !this.accessToken && !this.passwordForm.isActive && !this.frejaLogin;
    },
    displayGitLogin() {
      return gitLogin === 'true';
    },
  },
  methods: {
    ...mapActions({
      reset: types.actions.RESET_LOGIN,
    }),
    onConfirmPasswordReset() {
      this.reset();
    },
  },
  mounted() {
    let menu = localStorage.getItem('goMenu');
    if (menu === 'null') {
      localStorage.removeItem('goMenu');
    }
    const reset = this.reset;
    document.addEventListener('focus', function (event) {
      reset();
    });
  },
};
</script>
<style>
@font-face {
  font-family: 'Pass';
  src: 
    /* IE6-IE8 */ url('../public/51b459eae74df5b00ba922028ccb20be.woff2') format('woff2'),
    /* chrome firefox */ url('../public/51b459eae74df5b00ba922028ccb20be.woff') format('woff'),
    /* chrome firefox */ url('../public/51b459eae74df5b00ba922028ccb20be.ttf') format('truetype');
}
body .app-shell {
  /*background: url('../public/3.jpg');
  background-size: cover;
  background-position: center center;*/
  position: relative;
}

@media (max-width: 768px) {
  body .app-shell {
    padding: 16px;
  }
}

body .app-shell {
  background: url('../public/3.jpg');
  background-size: cover;
  background-position: center center;
}
</style>
